import React from 'react'
import NotFound404 from '../components/404/404'
import Page from '../components/Page/Page'

const NotFoundPage = () => (
  <Page>
    <NotFound404 />
  </Page>
)

export default NotFoundPage
